import React from 'react';
import '../../assets/css/blocks/games-filter.scss';
import moment from 'moment';
import onClickOutside from "react-onclickoutside";

class GamesFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listOpen: false,
            label: "Today",
            dates: []
        };
        this.toggleList = this.toggleList.bind(this);
        this.itemClick = this.itemClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setDates = this.setDates.bind(this);
    }

    handleClickOutside(){
        this.setState({
          listOpen: false
        })
      }

    toggleList() {
        this.setState(prevState => ({
            listOpen: !prevState.listOpen
        }))
    }

    setDates() {
        const pos = 3;
        let dif = 0
        let days = [];
        for (let i = 0; i < 10; i++) {
            let day = {};
            if (i < pos) {
                dif = pos - i;
                day.title = moment().subtract(dif, 'days').format('ddd Do MMM');
                day.selected = false;
                days.push(day);
            } else if (i === pos) {
                day.title = moment().format('ddd Do MMM');
                day.selected = true;
                days.push(day);
            } else {
                dif = i - pos;
                day.title = moment().add(dif, 'days').format('ddd Do MMM');
                day.selected = false;
                days.push(day);
            }
        }
        this.setState({dates: days});
    }

    componentDidMount() {
        this.setDates()
    }

    itemClick(i) {
        let dates = this.state.dates;
        let label = "Today";
        dates.forEach((el, index) => {
            if (i === index) {
                el.selected = !el.selected;
            } else {
                el.selected = false;
            }
        });
        this.setState({dates: dates, label: dates[i].selected ? dates[i].title : label});
        this.props.setDate(dates[i].selected ? dates[i].title : label);
        this.toggleList();
    }

    render() {
        return (
            <div className="games-filter">
                <div className="buttons-block center">
                    <button className="button button-gray">{moment().format('ddd Do MMM') !== this.state.label ? this.state.label : 'Today'}</button>

                    <div className="dropdown-wrapper">
                        <div className="dropdown-header" onClick={() => this.toggleList()}>
                            <div className="dropdown-header-title">Upcoming <i className="arrow arrow-up-white"></i></div>
                        </div>
                        <ul className={`dropdown-list ${this.state.listOpen ? "open" : ""}`}>
                            {this.state.dates.map((item, index) =>  (
                                <li className={`dropdown-list-item ${item.selected ? "active" : ""}`} key={index} onClick={() => this.itemClick(index)}>{moment().format('ddd Do MMM') === item.title ? 'Today' : item.title}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}


export default onClickOutside(GamesFilter);