import React from 'react';

import stop from '../assets/img/stop.png';
import {ReactComponent as CloseIcon} from '../assets/img/x-mark.svg';

import '../assets/css/blocks/exit-intent-modal.scss';
import { ga4react } from '../';
import { withRouter } from 'react-router-dom';

class ExitIntentModal extends React.Component {

  componentDidMount() {
    ga4react.event('OpenWait');
  }

  onJoinClick = () => {
    this.onClose();
    ga4react.event('JoinFromWait');
    this.props.history.push('/sign-up');
  }

  onClose = () => {
    ga4react.event('CloseWait');
    this.props.onClose();
  }

  render() {
    return (
      <div className="exit-intent-modal">
        <div className="modal">
            <img src={stop} />
            <span className="title">Wait!</span>
            <span>Try out our daily FREE tips first!</span>
            <a onClick={this.onJoinClick} className="sub-button">Join Now to Get FREE tips</a>
            <span onClick={this.onClose} className="row"><CloseIcon /><span className="close-text">i don’t want free winning tips</span></span>
        </div>
      </div>
    )
  }
}

export default withRouter(ExitIntentModal);