import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import logo from '../assets/img/sporita_logo.png';
import LogoutPopup from './LogoutPopup';

function Header(props) {
    const routesWithoutHeader = ['/sign-in', '/sign-up', '/pricing', '/blog', '/banner/sm/index.html', '/reset', '/click', '/give-more-info'];
    const [isModalOpen, setModalOpen] = React.useState(false);

    const openLogoutModal = () => {
        props.closeMenu();
        setModalOpen(true);
    }

    const isHeaderRendered = () => {
        let isRendered = true;
        routesWithoutHeader.forEach(route => {
            if (props.history.location.pathname.includes(route)) {
                isRendered = false;
            }
        })
        return isRendered;
    }
    return (
        <>
            {isModalOpen && <LogoutPopup overlayClick={() => setModalOpen(false)} />}
            {isHeaderRendered() ? <header className={`header ${props.menuOpen ? "upper-level" : ""}`}>
                <div className="container">
                    <div className="header-container">
                        <NavLink to="/" className="header-logo" onClick={props.closeMenu}>
                            <img src={logo} alt="logo" />
                        </NavLink>
                        <div className={`header-menu ${props.menuOpen ? "open" : null}`}  >
                            <ul>
                                <li>
                                    {/* <NavLink to="/bet-finder" className="header-menu-link" activeClassName="active" onClick={props.closeMenu}>Bet Finder</NavLink> */}
                                    <NavLink exact to="/" className="header-menu-link" activeClassName="active" onClick={props.closeMenu}>Matches</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/bet-finder" className="header-menu-link" activeClassName="active" onClick={props.closeMenu}>Bet Finder</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/vip-tips" className="header-menu-link" activeClassName="active" onClick={props.closeMenu}>VIP Tips</NavLink>
                                </li>
                                <li>
                                    <NavLink exact to={props.authenticated ? `/user-tips/${props.user.username}` : '/my-tips'} className="header-menu-link" activeClassName="active" onClick={props.closeMenu}>My Tips</NavLink>
                                </li>
                                <li>
                                    <NavLink exact to="/expert-users" className="header-menu-link" activeClassName="active" onClick={props.closeMenu}>Expert Users</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/pricing" className="header-menu-link" activeClassName="active" onClick={props.closeMenu}>Pricing</NavLink>
                                </li>
                                <li>
                                    <NavLink exact to="/blog" className="header-menu-link" activeClassName="active" onClick={props.closeMenu}>Blog</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/faq" className="header-menu-link" activeClassName="active" onClick={props.closeMenu}>FAQ</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/how-to" className="header-menu-link" activeClassName="active" onClick={props.closeMenu}>Knowledge hub</NavLink>
                                </li>
                                <li>
                                    {!props.authenticated ? <NavLink to="/sign-up" className="button button-blue" activeClassName="active" onClick={props.closeMenu}>Join</NavLink> : <a onClick={openLogoutModal} className="button button-blue">Logout</a>}
                                </li>
                            </ul>
                        </div>
                        <div className="mobile-menu-button" onClick={props.mobileMenuClick}>
                            <div id="nav-icon" className={props.menuOpen ? "open" : null}> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span></div>
                        </div>
                    </div>
                </div>
            </header> : null}
        </>
    )
}

const mapStateToProps = ({ auth }) => ({
    authenticated: auth.authenticated,
    user: auth.user,
});

export default withRouter(connect(mapStateToProps, null)(Header));