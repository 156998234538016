import React from "react";
import { connect } from "react-redux";
import {requestLogout} from '../redux/auth';
import '../assets/css/blocks/logout-popup.scss';

class LogoutPopup extends React.Component {

    onLogout = () => {
      this.props.requestLogout();
      this.props.overlayClick();
    }
    render() {
        return (
            <div className="logout-popup-modal">
                <div className="logout-popup-modal-overlay" onClick={this.props.overlayClick}></div>
                <div className="logout-popup-modal-box">
                    <div className="box-title">
                        {/* <div className="box-title-close" onClick={this.props.overlayClick}>Close</div> */}
                        Are you sure?
                    </div>
                    <div className="box-content">
                    <a onClick={this.onLogout} className="button button-blue">Logout</a>
                    <a onClick={this.props.overlayClick} className="button">Close</a>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
  requestLogout,
}
export default connect(null, mapDispatchToProps)(LogoutPopup);