import React from "react";
import _ from 'lodash';
import moment from 'moment';
import '../assets/css/pages/matches.scss';
import MatchesTable from '../components/Matches/MatchesTable';
import HocGamesFilter from '../components/Matches/GamesFilter';
import { Helmet } from 'react-helmet';
import http from "../services/http";
import SignalR from "../components/Matches/SignalR";
import GoVipBanner from "../components/Match/GoVipBanner";
import { VIP_USER_ROLE } from "../configs";
import { connect } from "react-redux";
import { Fragment } from "react";

class Matches extends React.Component {
  state = {
    matches: [],
    leagues: {},
    initialMatches: [],
    selectedDate: moment().format('YYYY-MM-DD'),
    loading: false,
    scrolled: false,
    isFilteredByStats: false
  };

  componentDidMount() {
    this.fetchMatches();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState.matches) !== JSON.stringify(this.state.matches) && prevState.matches.length) {
      const grouped = _(this.state.matches).groupBy(match => match.league.leagueId).sortBy(group => this.state.matches.indexOf(group[0])).value();
      this.setState({ leagues: grouped });
    }
  }

  fetchMatches = async (date = this.state.selectedDate) => {
    try {
      this.setState({ loading: true });
      const now = new Date();
      const isToday = moment().format('YYYY-MM-DD') === date;
      const offsetInHours = now.getTimezoneOffset() / 60;
      let matchesData = [];
      if (isToday) {
        const { data } = await http.get(`/matches/for/today/offset/${offsetInHours * -1}`);
        matchesData = [...data];
      } else {
        const { data } = await http.get(`/matches/for/${date}/offset/${offsetInHours * -1}`);
        matchesData = [...data];
      }
      const grouped = _(matchesData).groupBy(match => match.league.leagueId).sortBy(group => matchesData.indexOf(group[0])).value();
      this.setState({ matches: matchesData, leagues: grouped, initialMatches: matchesData });
      document.addEventListener('scroll', () => {
        if (!this.state.scrolled) {
          this.setState({ scrolled: true })
        }
      })
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  setSelectedDate = (date) => {
    this.fetchMatches(moment(date, 'ddd Do MMM').format('YYYY-MM-DD'));
    this.setState({ selectedDate: date });
  }

  onNewMessage = (message) => {
    const newMatches = this.state.matches.map(m => {
      return m.matchId === message.matchId ?
        {
          ...m,
          status: message.status,
          minute: message.minute,
          homeTeamScore: message.homeTeamScore,
          awayTeamScore: message.awayTeamScore,
          awayRedCards: message.awayRedCards,
          homeRedCards: message.homeRedCards,
        } : m;
    });
    this.setState({ matches: [...newMatches] })
  }

  finalLeagues = (leagues) => {
    const touchDevice = ('ontouchstart' in document.documentElement);
    const numberOfLeagues = touchDevice ? 3 : 10;
    return this.state.scrolled ? leagues : leagues.splice(0, numberOfLeagues)
  };


  renderAdBlock = () => {
    const { user, authenticated } = this.props;
    const isVip = user?.role === VIP_USER_ROLE;
    if (isVip) {
      return null;
    }
    if (!authenticated) {
      return null;
    }
    return (
      <a href="https://qvlzqw.womenforlove.net/c/3f33acd3b135bb12?s1=152133&s2=1391355&j5=1&j6=1" target="_blank">
        <div className="ad-block" />
      </a>
    )
  }

  renderVipBanner = (index) => {
    const { user } = this.props;
    const isVip = user?.role === VIP_USER_ROLE;
    if (isVip) {
      return null;
    }
    if ((index % 10 === 0)) {
      return (
        <Fragment>
          <GoVipBanner />
          {/* {this.renderAdBlock()} */}
        </Fragment>
      );
    }
    return null
  }

  sortByStats = () => {
    const { user } = this.props;
    let matchesData = [...this.state.matches];

    this.state.matches = matchesData.sort((a, b) => b.homeWinPercentage - a.homeWinPercentage);


    const grouped = _(matchesData).groupBy(match => match.league.leagueId).sortBy(group => matchesData.indexOf(group[0])).value();
    this.setState({ matches: matchesData, leagues: grouped });
    if (this.state.isFilteredByStats) {
      let matchesData = [...this.state.initialMatches];

      const grouped = _(matchesData).groupBy(match => match.league.leagueId).sortBy(group => matchesData.indexOf(group[0])).value();
      this.setState({ matches: matchesData, leagues: grouped, isFilteredByStats: false });

    } else {
      this.setState({
        isFilteredByStats: true
      })
    }
  }

  renderStatsButton = () => {
    const { user } = this.props;
    const isVip = user?.role === VIP_USER_ROLE;
    if (!isVip) {
      return null;
    }
    return <button onClick={() => this.sortByStats()} className={`button button-gray ${this.state.isFilteredByStats ? 'checked' : ''}`}>
      AI Home Win
    </button>;
  }

  render() {
    return (
      <div className="matches">
        <Helmet>
          <title>Sporita Football Predictions</title>
          <meta name="description" content="Football analysis and prediction tool.When Big Data meets football betting! Make smart football bets with Sporita.Check it out." />
        </Helmet>
        <SignalR onNewMessage={this.onNewMessage} />
        <div className="container layers">
          <div className="layer layer-top">
            <HocGamesFilter dates={this.state.dates} setDate={this.setSelectedDate} />
          </div>

          {this.renderStatsButton()}
          <div className="layer layer-bottom">
            {!this.state.loading ? this.finalLeagues(Object.keys(this.state.leagues)).map((league, index) => {
              return (
                <React.Fragment key={index}>
                  <MatchesTable goalsMatches={this.state.goalsMatches} table={this.state.leagues[league]} />
                  {this.renderVipBanner(index)}
                </React.Fragment>
              )
            }) : [1, 2, 3].map((league, index) => {
              return (
                <React.Fragment key={index}>
                  <MatchesTable goalsMatches={[]} loading table={[]} />
                </React.Fragment>
              )
            })}
            {!Object.keys(this.state.leagues).length && !this.state.loading ? <h3 className="banner-title" style={{ fontSize: '26px', fontWeight: 700, textAlign: 'center' }}>No matches are currently available for this date</h3> : null}
          </div>


        </div>
      </div>

    )
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
  authenticated: auth.authenticated,
});

export default connect(mapStateToProps, null)(Matches);