import React from 'react';


import '../assets/css/pages/click.scss';

import axios from 'axios';
import { baseURL } from '../services/http';

const ClickPage = () => {
  const fetchInfo = React.useCallback(async () => {
    const {data} = await axios.post(`${baseURL}/analytics/click`, {
      url: window.location.href,
    });
    window.location.assign(data.url);
  }, []);

  React.useEffect(() => {
    fetchInfo();
  }, [fetchInfo]);

  return (
    <div className="click-container">
      <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    
  )
};

export default ClickPage;