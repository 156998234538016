import React from 'react';
import PolicyStorage from '../services/storage/policy';

import '../assets/css/blocks/policy-block.scss';
import { connect } from 'react-redux';

const countries = [
  'BE',
  'BG',
  'CZ',
  'DK',
  'DE',
  'EE',
  'IE',
  'EL',
  'ES',
  'FR',
  'HR',
  'IT',
  'CY',
  'LV',
  'LT',
  'LU',
  'HU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'FI',
  'SE',
  'IS',
  'LI',
  'NO',
  'CH',
  'US',
  'CA',
];

class Policy extends React.Component {
  state = {
    isDisplay: false,
  };

  async componentDidUpdate(prevProps) {
    const touchDevice = ('ontouchstart' in document.documentElement);
    if (JSON.stringify(this.props.ipinfo) !== JSON.stringify(prevProps.ipinfo)) {
      const isPolicyAccepted = PolicyStorage.get();
      if (isPolicyAccepted) {
        return;
      }
      if (!touchDevice) {
        return setTimeout(() => this.setState({isDisplay: countries.includes(this.props.ipinfo.country)}), 3000);
      } else {
        this.setState({isDisplay: countries.includes(this.props.ipinfo.country)});
      }
    }
  }

  onAcceptPress = () => {
    PolicyStorage.save('accepted');
    this.setState({isDisplay: false});
  }

  render() {
    const {isDisplay} = this.state;
    return (
      <div className={`policy-block-container ${isDisplay ? 'show' : 'hide'}`} >
          <div className="left-part">
            <h3 className="title">We Care About Your Privacy</h3>
            <p className="content bordered">We and our partners store and/or access information on a device, such as unique IDs in cookies to process personal data. You may accept or manage your choices by clicking below, including your right to object where legitimate interest is used, or at any time in the privacy policy page. These choices will be signaled to our partners and will not affect browsing data.</p>
          </div>
          <div className="right-part">
            <h3 className="title">We and our partners process data to provide:</h3>
            <p className="content">Store and/or access information on a device. Create a personalised content profile. Develop and improve products. Personalised ads, and ad measurement. Personalised content display, content measurement and audience insights. </p>
          </div>
          <div className="button-container">
            <button onClick={this.onAcceptPress}>I Accept</button>
          </div>
        </div>
      )
  }
}

const mapStateToProps = ({app}) => ({
  ipinfo: app.ipinfo,
});

export default connect(mapStateToProps, null)(Policy);
