import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { requestSaveUrl } from '../redux/app';

const GoVipButton = ({authenticated, history, title = "Unlock", isPrimary = true, fromVipBanner, requestSaveUrl}) => {

  const onButtonPress = () => {
    if (authenticated) {
      history.push('/pricing');
    } else {
      if (fromVipBanner) {
        requestSaveUrl('/pricing');
        history.push('/sign-up?redirect=pricing');
      } else {
        history.push('/sign-up')
      }
    }
  }

  return (
    <button style={{backgroundColor: isPrimary ? '#f6a11a' : '#1b4b6a'}} onClick={onButtonPress} className="banner-button">
      {title}
    </button>
  )
}

const mapStateToProps = ({auth}) => ({
  authenticated: auth.authenticated,
});

const mapDispatchToProps = {
  requestSaveUrl,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoVipButton));
