import {
  call,
  takeEvery,
  put,
} from 'redux-saga/effects';
import http from '../../services/http';
import UserStorage from '../../services/storage/user';
import TokenStorage from '../../services/storage/token';
import GuidStorage from '../../services/storage/guid';
import { ga4react } from '../..';

const SIGNIN_REQUEST = 'auth/SIGNIN_REQUEST';
const SIGNIN_SUCCESS = 'auth/SIGNIN_SUCCESS';
const SIGNIN_FAILURE = 'auth/SIGNIN_FAILURE';

const SIGNUP_REQUEST = 'auth/SIGNUP_REQUEST';
const SIGNUP_SUCCESS = 'auth/SIGNUP_SUCCESS';
const SIGNUP_FAILURE = 'auth/SIGNUP_FAILURE';

const UPDATE_TOKEN_REQUEST = 'auth/UPDATE_TOKEN_REQUEST';
const UPDATE_TOKEN_SUCCESS = 'auth/UPDATE_TOKEN_SUCCESS';

const LOGOUT_REQUEST = 'auth/LOGOUT_REQUEST';
const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';

export const initialState = {
  authLoading: false,
  authenticated: false,
  user: {},
  authError: false,
  signUpError: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SIGNIN_REQUEST:
      return {...state, authLoading: true, authError: false};
    case SIGNIN_SUCCESS:
      return {
        ...state,
        authLoading: false,
        authenticated: true,
        user: action.user,
        authError: false
      };
    case SIGNIN_FAILURE:
      return {...state, authLoading: false, authError: true};

    case SIGNUP_REQUEST:
        return {...state, authLoading: true, authError: false, signUpError: false};
    case SIGNUP_SUCCESS:
        return {
          ...state,
          authLoading: false,
          authenticated: true,
          user: action.user,
          authError: false,
          signUpError: false
        };
    case SIGNUP_FAILURE:
        return {...state, authLoading: false, authError: true, signUpError: true};

    case UPDATE_TOKEN_REQUEST:
      return {...state};
    case UPDATE_TOKEN_SUCCESS:
      return {...state, user: action.user};

    case LOGOUT_REQUEST:
      return {...initialState}
    default:
      return state;
  }
}

// <<<ACTIONS>>>
export const requestSignIn = payload => ({
  type: SIGNIN_REQUEST,
  payload,
});

export const requestSignUp = payload => ({
  type: SIGNUP_REQUEST,
  payload,
});

export const requestLogout = () => ({
  type: LOGOUT_REQUEST,
});

export const requestUpdateToken = () => ({
  type: UPDATE_TOKEN_REQUEST,
});

// <<<WORKERS>>>
function* signUp({payload}) {
  try {
    const {data} = yield call(http.post, '/Account/register', payload);
    const guid = GuidStorage.get();
    yield call(http.post, '/Analytics/action', {
      "uaid": 0,
      "uid": data.user.id,
      "addinfo": guid
    });
    ga4react.event('sign_up');
    window._sendx.identify({ email: payload.email, firstName: payload.username,
    success: function(){console.log("123identify call successful");},
    failure: function(){console.log("123identify failed");},
  })
    yield call(TokenStorage.save, data.token);
    yield call(UserStorage.save, data.user);
    yield put({
      type: SIGNUP_SUCCESS,
      user: data.user,
    });

  } catch (error) {
    yield put({
      type: SIGNUP_FAILURE,
    });
  }
}

function* signIn({payload}) {
  try {
    const {data} = yield call(http.post, '/Account/login', payload);
    yield call(TokenStorage.save, data.token);
    yield call(UserStorage.save, data.user);
    yield put({
      type: SIGNIN_SUCCESS,
      user: data.user,
    });
  } catch (error) {
    yield put({
      type: SIGNIN_FAILURE,
    });
  }
}

function* logout() {
  try {
    yield call(UserStorage.delete);
    yield call(TokenStorage.delete);
    yield call(GuidStorage.delete);
    yield put({
      type: LOGOUT_SUCCESS,
    });
  } catch (error) {
    console.log('logout-error', error);
  }
}

function* updateToken() {
  try {
    const {data} = yield call(http.get, '/Account/token');
    yield call(TokenStorage.save, data.token);
    yield call(UserStorage.save, data.user);
    yield put({
      type: UPDATE_TOKEN_SUCCESS,
      user: data.user,
    });
  } catch (error) {
    console.log('updateToken-error', error);
  }
}

// <<<WATCHERS>>>
export function* watchSignIn() {
  yield takeEvery(SIGNIN_REQUEST, signIn);
}

export function* watchSignUp() {
  yield takeEvery(SIGNUP_REQUEST, signUp);
}

export function* watchLogOut() {
  yield takeEvery(LOGOUT_REQUEST, logout);
}

export function* watchUpdateToken() {
  yield takeEvery(UPDATE_TOKEN_REQUEST, updateToken);
}