import React from "react";
import moment from 'moment';
import loadable from '@loadable/component'

import PayStorage from './services/storage/pay';

import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import TokenStorage from './services/storage/token';

import Matches from "./pages/Matches";

import Header from "./components/Header";
import Footer from "./components/Footer";
import ProtectedRoute from "./ProtectedRoute";
import { connect } from "react-redux";
import VisitsComponent from "./VisitsComponent";
import Policy from "./components/Policy";

import { requestLogout, requestUpdateToken } from "./redux/auth";
import { getIpinfoRequest } from "./redux/app";
import exitIntent from './utils/ExitIntent';

import ExitIntentModal from "./components/ExitIntentModal";
// import LocationPopup from "./components/LocationPopup";
// import { VIP_USER_ROLE } from "./configs";

import './assets/css/main.scss';
import ResetPassword from "./pages/ResetPassword";
import ClickPage from "./pages/ClickPage";

const Match = loadable(() => import('./pages/Match'));
const BetFinder = loadable(() => import('./pages/BetFinder'));
const MyTips = loadable(() => import('./pages/MyTips'));
const VipTips = loadable(() => import('./pages/VipTips'));
const UsersTips = loadable(() => import('./pages/UsersTips'));
const SignUp = loadable(() => import('./pages/SignUp'));
const GiveMoreInfo = loadable(() => import('./pages/GiveMoreInfo'));

const Login = loadable(() => import('./pages/Login'));
const Logout = loadable(() => import('./pages/Logout'));

const Pricing = loadable(() => import('./pages/Pricing'));
const Success = loadable(() => import('./pages/Success'));
const FreeTips = loadable(() => import('./pages/FreeTips'));
const SmBanner = loadable(() => import('./pages/SmBanner'));
const ViewBlog = loadable(() => import('./pages/ViewBlog'));
const UserTips = loadable(() => import('./pages/UserTips'));
const Blog = loadable(() => import('./pages/Blog'));
const FAQ = loadable(() => import('./pages/FAQ'));
const HowTo = loadable(() => import('./pages/HowTo'));

const Privacy = loadable(() => import('./pages/Privacy'));
const Terms = loadable(() => import('./pages/Terms'));

class AppRouter extends React.Component {
    state = {
        menuOpen: false,
        showPopup: false,
    };

    mobileMenuClick = () => {
        this.setState(state => ({
            menuOpen: !state.menuOpen
        }));
    }

    closeMenu = () => {
        this.setState(state => ({
            menuOpen: false
        }));
    }

    getExpFromToken = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        const parsed = JSON.parse(jsonPayload)
        return parsed.exp;
    };

    async componentDidMount() {
        console.log("blalbalblab");
        if (this.props.location.pathname === '/sign-out') {
            return;
        }
        if (this.props.location.pathname === '/banner/sm/index.html') {
            return;
        }
        if (this.props.location.pathname === '/click') {
            return;
        }
        if (this.props.authenticated) {
            this.props.requestUpdateToken();
        }
        this.props.getIpinfoRequest();
        const locationsWithoutPopup = ['/sign-up', '/sign-in', '/pricing', '/banner/sm/index.html', '/reset', '/click'];
        setTimeout(() => {
            exitIntent({
                maxDisplays: 99999,                    // default 99999
                eventThrottle: 1000,                    // default 200
                showAfterInactiveSecondsDesktop: 300,   // default 60
                showAfterInactiveSecondsMobile: 60,    // default 40
                showAgainAfterSeconds: 100,             // default 10
                onExitIntent: () => {
                    const sessionPopUp = sessionStorage.getItem('popup');
                    if (!sessionPopUp && !this.props.authenticated && !locationsWithoutPopup.includes(this.props.location.pathname)) {
                        this.setState({ showPopup: true })
                        sessionStorage.setItem('popup', 'open');
                    }
                },
                debug: false,
            })
        }, 5000)
    }

    componentDidUpdate() {
        if (this.props.location.pathname === '/banner/sm/index.html') {
            return;
        }
        if (this.props.location.pathname === '/click') {
            return;
        }

        const token = TokenStorage.get();
        if (!token) {
            return;
        }
        const exp = this.getExpFromToken(token);
        const isExpire = !moment(moment().unix()).isBefore(exp);
        if (isExpire) {
            this.props.requestLogout();
        }
    }

    render() {
        let payed = '';
        if (this.props.location.pathname !== '/banner/sm/index.html') {
            payed = PayStorage.get();
        }
        return (
            <VisitsComponent>
                {this.state.showPopup && <ExitIntentModal onClose={() => this.setState({ showPopup: false })} />}
                <div className={`${this.state.menuOpen ? "no-scroll" : null}`}>
                    <Header menuOpen={this.state.menuOpen} closeMenu={this.closeMenu} mobileMenuClick={this.mobileMenuClick} />
                    <main className="main">
                        <Switch>
                            <Route path="/" exact component={Matches} />
                            <Route path="/match/:matchId/:params" exact component={Match} />
                            <Route path="/match/:matchId" exact component={Match} />
                            <Route path="/bet-finder" component={BetFinder} />
                            <Route path="/my-tips" component={MyTips} />
                            <Route path="/vip-tips" component={VipTips} />
                            <Route path="/free-tips" component={FreeTips} />
                            <Route path="/expert-users" exact component={UsersTips} />
                            <Route path="/user-tips/:username" exact component={UserTips} />
                            <Route path="/blog" exact component={Blog} />
                            <Route path="/blog/:blog" component={ViewBlog} />
                            <Route path="/banner/sm/index.html" exact component={SmBanner} />
                            <Route path="/click" exact component={ClickPage} />
                            <ProtectedRoute authenticated={false} path="/sign-out" exact component={Logout} />

                            <ProtectedRoute path="/success" authenticated={payed !== 'ok'} component={Success} />
                            <ProtectedRoute path="/sign-in" authenticated={!this.props.authenticated} component={Login} />
                            <ProtectedRoute path="/sign-up" authenticated={!this.props.authenticated} component={SignUp} />
                            <Route path="/give-more-info/:email" authenticated={!this.props.authenticated} exact component={GiveMoreInfo} />

                            <Route path="/reset" component={ResetPassword} />
                            <Route path="/pricing" component={Pricing} />
                            <Route path="/faq" component={FAQ} />
                            <Route path="/how-to" component={HowTo} />

                            <Route path="/privacy-policy" component={Privacy} />
                            <Route path="/terms-and-conditions" component={Terms} />

                        </Switch>
                    </main>
                    <Footer />
                </div>
                {/* {(this.props.authenticated && this.props.user?.role !== VIP_USER_ROLE) ?  <LocationPopup /> : null} */}
            </VisitsComponent>
        )
    }

}

const mapStateToProps = ({ auth }) => ({
    authenticated: auth.authenticated,
    user: auth.user,
});

const mapDispatchToProps = {
    requestLogout,
    requestUpdateToken,
    getIpinfoRequest,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppRouter));