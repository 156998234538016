import React from 'react';
import '../../assets/css/blocks/go-vip-banner.scss';
import GoVipButton from '../GoVipButton';

class GoVipBanner extends React.Component {
    render() {
        return (
            <div className={`go-vip-banner ${this.props.bannerClass}`}>
                <h3 className="banner-title">Get access to</h3>
                <ul className="banner-list">
                    <li>Up to 30% ROI Vip Tips!</li>
                    <li>+500 profitable leagues!</li>
                    <li>Extra profitable betting markets!</li>
                    <li>Top-performing expert tipsters!</li>
                </ul>
                  <GoVipButton fromVipBanner />
            </div>
        )
    }
}

export default GoVipBanner;