import React from 'react';
import { withRouter } from 'react-router-dom';
import adultsOnly from '../assets/img/18logo.png';
import gameCare from '../assets/img/GamCare-logo.png';
import beGambleAware from '../assets/img/begambleawareorg_black.png';

const Footer = (props) => {
  const routesWithoutFooter = ['/sign-in', '/sign-up', '/blog', '/banner/sm/index.html', '/reset', '/click'];

  const isFooterRendered = () => {
    return !routesWithoutFooter.some(route => props.history.location.pathname.includes(route));
  }

  return (
    <>
      <style>
        {`
        .footer-left{
          
    display: flex;
    justify-content: center;
    align-items: center;

        }
          .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px; /* Adjust padding as needed */
          }
          .footer-content {
            text-align: center; /* Center footer content text */
            flex-grow: 1;
          }
          .footer-left img {
            margin-right: 16px; /* Increased space between logos */
          }
          .footer-18-plus-logo {
            height: auto; /* Adjusted height */
            width: 48px; /* Adjusted width */
          }
          .footer-begambleaware-logo {
            height: auto; /* Maintain aspect ratio */
            width: 169px; /* Adjusted width */
          }
          .footer-gamecare-logo{
            height: auto; /* Maintain aspect ratio */
            width: 100px; /* Adjusted width */
          }
          .footer-link-gap {
            margin: 0 10px; /* Adjust gap as needed */
          }
        `}
      </style>
      {isFooterRendered() && (
        <footer className="footer">

          <div className="footer-content">
            <span>© 2024 Sporita, Inc</span>
            <div className="footer-links mb-3">
              <a href="/terms-and-conditions" className="footer-link">Terms & Conditions</a>
              <span className="footer-link-gap"> | </span>
              <a href="/privacy-policy" className="footer-link">Privacy Policy</a>
            </div>
            <div className="footer-left">
              <a rel="noopener noreferrer">
                <img src={adultsOnly} alt="18+" className="footer-18-plus-logo" />
              </a>
              <a href="https://www.begambleaware.org/" target="_blank" rel="noopener noreferrer">
                <img src={beGambleAware} alt="BeGambleAware.org" className="footer-begambleaware-logo" />
              </a>
              <a href="https://www.gamcare.org.uk/" target="_blank" rel="noopener noreferrer" style={{display:'flex', alignItems:'center'}}>
                <img src={gameCare} alt="GamCare" className="footer-gamecare-logo" />
              </a>
            </div>
          </div>
        </footer>
      )}
    </>
  );
}

export default withRouter(Footer);
