import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import './assets/css/bootstrap-grid.min.css';
import AppRouter from "./Routes";

function App() {
    return (
        <div className="App">
            <Router>
                <AppRouter/>
            </Router>
        </div>
    );
}

export default App;
