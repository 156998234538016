import React from 'react';
import moment from 'moment';

import { ReactComponent as LockIcon } from '../../assets/img/lock.svg';
import '../../assets/css/blocks/matches-table.scss';
import { connect } from 'react-redux';
import { VIP_USER_ROLE } from '../../configs';

const HIGHLIGHT_TIMEOUT = 3000;

class MatchRow extends React.Component {

  state = {
    scoreChanged: false,
    cardsChanged: false,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.homeTeamScore !== this.props.match.homeTeamScore) {
      this.setState({scoreChanged: true});
      setTimeout(() => this.setState({scoreChanged: false}), HIGHLIGHT_TIMEOUT);
    }
    if (prevProps.match.awayTeamScore !== this.props.match.awayTeamScore) {
      this.setState({scoreChanged: true});
      setTimeout(() => this.setState({scoreChanged: false}), HIGHLIGHT_TIMEOUT);
    }

    if (prevProps.match.homeRedCards !== this.props.match.homeRedCards) {
      this.setState({cardsChanged: true});
      setTimeout(() => this.setState({cardsChanged: false}), HIGHLIGHT_TIMEOUT);
    }
    if (prevProps.match.awayRedCards !== this.props.match.awayRedCards) {
      this.setState({cardsChanged: true});
      setTimeout(() => this.setState({cardsChanged: false}), HIGHLIGHT_TIMEOUT);
    }
  }

  renderMobileMatchStatus = () => {
    const {match} = this.props;
    switch (match.status) {
      case 'Live':
        return <span className="score score-live"> <span className="text-blue">{match.homeTeamScore} - {match.awayTeamScore}</span> (<span className="text-blue text-bold">{match.minute}<span className="blink">'</span></span>) </span>;
      case 'Finished':
        return <span className="score"> {match.homeTeamScore} - {match.awayTeamScore} </span>;
      default:
        return (
          <span className="teams-pts">
            {this.isVip() ? <span className="team-pts home">{match.homeTeamPts}</span> : <span className="team-pts home"><LockIcon fill="white" opacity="1" width={11} height={11} /></span>}
            <span className="match-date">{moment(match.matchDate).format('HH:mm')}</span>
            {this.isVip() ? <span className="team-pts away">{match.awayTeamPts}</span> : <span className="team-pts away"><LockIcon fill="white" opacity="1" width={11} height={11} /></span>}
            </span>
        )
    }
  }
  renderMatchStatus = () => {
    const {match} = this.props;
    switch (match.status) {
      case 'Live':
        return <span className="score text-blue"> {match.homeTeamScore} - {match.awayTeamScore} </span>;
      case 'Finished':
        return <span className="score"> {match.homeTeamScore} - {match.awayTeamScore} </span>;
      default:
        return (
          <span className="teams-pts">
            {
              this.isVip() ?
                <>
                  <span className="team-pts home">{match.homeTeamPts}</span>
                  <span className="team-pts away">{match.awayTeamPts}</span>
                </> :
                <>
                  <span className="team-pts home"><LockIcon fill="white" opacity="1" width={11} height={11} /></span>
                  <span className="team-pts away"><LockIcon fill="white" opacity="1" width={11} height={11} /></span>
                </>
            }
          </span>
        )
    }
  }

  renderDateSwitch = (date, finished, live, liveTime) => {
    const isToday = moment(date).isSame(moment(), 'date');
    let param;
    if (finished) {
        param = "finished"
    } else if (live) {
        param = "live"
    }
    switch (param) {
        case 'finished':
            return (<span>{moment(date).format('HH:mm')}</span>);
        case 'live':
            return (<span className="match-live">{liveTime}<span className="blink">'</span> <span className="live-badge">LIVE</span></span>);
        default:
            return (<span>{isToday ? `${moment(date).format('HH:mm')}` : `${moment(date).format('HH:mm')}`}</span>)
    }
  }

  isVip = () => {
    if (this.props.user?.role === VIP_USER_ROLE) {
      return true;
    }
    if (!this.props.match.leagueVip) {
      return true;
    }
    return false;
  }
  
  renderStats = (match) => {
    const {scoreChanged, cardsChanged} = this.state;
    if (scoreChanged) {
      return <div className="table-field table-field-stats">Goal!</div>;
    }
    if (cardsChanged) {
      return <div className="table-field table-field-stats">Red Card!</div>
    }
    if (!match.odds.length) {
      return null
    }
    return (
      <div className="table-field table-field-stats">
        <div className="data-fields">
            {
              this.isVip() ?
              <>
                <div>{(match.odds[0].stat)}%</div>
                <div>{(match.odds[1].stat)}%</div>
                <div>{(match.odds[2].stat)}%</div>
              </> :
              <>
                <div><LockIcon width={15} height={15} /></div>
                <div><LockIcon width={15} height={15} /></div>
                <div><LockIcon width={15} height={15} /></div>
              </>
            }
        </div>
      </div>
    )
  }
  renderMobileStats = (match) => {
    const {scoreChanged, cardsChanged} = this.state;
    if (scoreChanged) {
      return <div className="table-field table-field-stats">Goal!</div>;
    }
    if (cardsChanged) {
      return <div className="table-field table-field-stats">Red Card!</div>
    }
    if (!match.odds.length) {
      return null;
    }
    return (
      <div className="table-field table-field-stats">
        <div className="data">
            <div className="data-name">Stats:</div>
            <div className="data-fields">
              {
                this.isVip() ?
                <>
                  <div>{(match.odds[0].stat)}%</div>
                  <div>{(match.odds[1].stat)}%</div>
                  <div>{(match.odds[2].stat)}%</div>
                </> :
                <>
                  <div><LockIcon width={15} height={15} /></div>
                  <div><LockIcon width={15} height={15} /></div>
                  <div><LockIcon width={15} height={15} /></div>
                </>
              }
            </div>
        </div>
      </div>
    )
  }
  render() {
    const {match, isMobile, toMatch} = this.props;
    const {scoreChanged, cardsChanged} = this.state;
    if (isMobile) {
      return (
        <div className={`table-row double-row ${(scoreChanged || cardsChanged) ? "table-row-higlighted" : ""}`}>
            <div className="table-row-top" onClick={() => toMatch(match)}>

                <div style={{textAlign: 'end'}} className="table-field table-field-team home">
                <div className="team-cards">
                  {match?.homeRedCards !== -1 ? Array.from(Array(match.homeRedCards).keys()).map((cardNumber) => {
                      return (
                          <span className={`card card-red`} key={cardNumber}></span>
                      )
                  }
                  ) : null}
              </div>
                    <span style={{textDecoration: 'underline'}} className="team-name">{match.homeTeam.name}</span>
                </div>
                <div className="table-field table-field-pts">
                    {this.renderMobileMatchStatus()}
                </div>
                <div style={{textAlign: 'start'}} className="table-field table-field-team away">
                  <div className="team-cards">
                    {match?.awayRedCards !== -1 ? Array.from(Array(match.awayRedCards).keys()).map((cardNumber) => {
                        return (
                            <span className={`card card-red`} key={cardNumber}></span>
                        )
                    }
                    ) : null}
                  </div>
                    <span style={{textDecoration: 'underline'}} className="team-name">{match.awayTeam.name}</span>

                </div>
            </div>
            <div className={"table-row-bottom"}>

                {this.renderMobileStats(match)}

                <div className="table-field table-field-odds">
                    <div className="data">
                        <div className="data-name">Odds:</div>
                        <div className="data-fields">
                        {
                          this.isVip() ?
                          <>
                            {match.odds.length ? <>
                              <div><span>{match.odds[0].odd}</span></div>
                              <div><span>{match.odds[1].odd}</span></div>
                              <div><span>{match.odds[2].odd}</span></div>
                            </> : null}
                          </> :
                          <>
                            <div><LockIcon width={15} height={15} /></div>
                            <div><LockIcon width={15} height={15} /></div>
                            <div><LockIcon width={15} height={15} /></div>
                          </>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
    return (
      <div className={`table-row ${(scoreChanged || cardsChanged) ? "table-row-higlighted" : ""}`}>
          <div className="table-field table-field-date">
              {this.renderDateSwitch(match.matchDate, match.status === 'Finished', match.status === 'Live', match.minute)}
          </div>

          <div className="table-field table-field-team home" onClick={() => toMatch(match)}>
              <div className="team-cards">
                  {match?.homeRedCards !== -1 ? Array.from(Array(match.homeRedCards).keys()).map((cardNumber) => {
                      return (
                          <span className={`card card-red`} key={cardNumber}></span>
                      )
                  }
                  ) : null}
              </div>
              <span style={{textDecoration: 'underline'}} className="team-name">{match.homeTeam?.name}</span>
          </div>
          <div className="table-field table-field-pts" onClick={() => toMatch(match)}>
            {this.renderMatchStatus()}
          </div>
          <div className="table-field table-field-team away" onClick={() => toMatch(match)}>
              <span style={{textDecoration: 'underline'}} className="team-name">{match.awayTeam?.name}</span>
                  <div className="team-cards">
                      {match?.awayRedCards !== -1 ? Array.from(Array(match.awayRedCards).keys()).map((cardNumber) => {
                          return (
                              <span className={`card card-red`} key={cardNumber}></span>
                          )
                      }
                      ) : null}
                  </div>
          </div>

          {this.renderStats(match)}
          
          <div className="table-field table-field-odds">
              <div className="data-fields">
                {
                  this.isVip() ?
                  <>
                    {match.odds.length ? <>
                      <div><span>{match.odds[0].odd}</span></div>
                      <div><span>{match.odds[1].odd}</span></div>
                      <div><span>{match.odds[2].odd}</span></div>
                    </> : null}
                  </> :
                  <>
                    <div><LockIcon width={15} height={15} /></div>
                    <div><LockIcon width={15} height={15} /></div>
                    <div><LockIcon width={15} height={15} /></div>
                  </>
                }
              </div>
          </div>
      </div>)
  }
}

const mapStateToProps = ({auth}) => ({
  user: auth.user,
});

export default connect(mapStateToProps, null)(MatchRow);