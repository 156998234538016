import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {requestSaveUrl} from './redux/app';
import http from './services/http';
import GuidStorage from './services/storage/guid';

class VisitsComponent extends React.Component {
  
  async componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      window.scrollTo(0, 0);
    }
    if (JSON.stringify(this.props.ipinfo) !== JSON.stringify(prevProps.ipinfo)) {
      const guidFromStorage = GuidStorage.get();
      if (guidFromStorage) {
        return;
      }
      const device = this.detectDevice();
      let u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);
      let guid = [u.substr(0,8), u.substr(8,4), '4000-8' + u.substr(13,3), u.substr(16,12)].join('-');
      await http.post('/Analytics/visit', {
        url: document.URL,
        "vtoken": guid,
        "device": device,
        "uagent": window.navigator.userAgent,
        "country": this.props.ipinfo.country,
        "city": this.props.ipinfo.city,
      });
      GuidStorage.save(guid);
    }
  }

  detectDevice = () => {
    const ua = navigator.userAgent.toLowerCase();
    if (/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(ua)) {
      return 1; // tablet
    } else if (/(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(ua)) {
      return 2; // phone
    } else return 0; // desktop
  };

  // async componentDidMount() {
  //   const guidFromStorage = GuidStorage.get();
  //   if (guidFromStorage) {
  //     return;
  //   }
  //   const device = this.detectDevice();
  //   const {data: ipData} = await Axios.get('https://ipinfo.io/?token=170cb214aa0e21');
  //   let u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);
  //   let guid = [u.substr(0,8), u.substr(8,4), '4000-8' + u.substr(13,3), u.substr(16,12)].join('-');
  //   await http.post('/Analytics/visit', {
  //     url: document.URL,
  //     "vtoken": guid,
  //     "device": device,
  //     "uagent": window.navigator.userAgent,
  //     "country": ipData.country,
  //     "city": ipData.city,
  //   });
  //   GuidStorage.save(guid);
  // }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (location.pathname !== '/sign-up' && location.pathname !== '/sign-in') {
        this.props.requestSaveUrl(location.pathname);
      }
    });
  }
  componentWillUnmount() {
      this.unlisten();
  }
  render() {
     return (
         <div>{this.props.children}</div>
      );
  }
}

const mapStateToProps = ({app}) => ({
  url: app.url,
  ipinfo: app.ipinfo,
});

const mapDispatchToProps = {
  requestSaveUrl,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VisitsComponent));