import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom'
import '../../assets/css/blocks/matches-table.scss';
import { VIP_USER_ROLE } from '../../configs'
import MatchRow from './MatchRow';

import {toMatch} from '../../utils'
import { connect } from 'react-redux';

class MatchesTable extends React.Component {

    renderDateSwitch = (date, finished, live, liveTime) => {
        const isToday = moment(date).isSame(moment(), 'date');
        let param;
        if (finished) {
            param = "finished"
        } else if (live) {
            param = "live"
        }
        switch (param) {
            case 'finished':
                return (<span>{moment(date).format('HH:mm')} pm</span>);
            case 'live':
                return (<span className="match-live">{liveTime}' <span className="live-badge">LIVE</span></span>);
            default:
                return (<span>{isToday ? `${moment(date).format('hh:mm a')}` : `${moment(date).format('hh:mm a')}`}</span>)
        }
    }

    getLoadingViewStyles = (width = 35) => ({
        width,
        height: 21,
        backgroundColor: '#f2f2f2',
    });
    
    goToPricing = () => {
        const {authenticated, history} = this.props;
        if (authenticated) {
            history.push('/pricing');
          } else {
            history.push('/sign-in');
          }
    }
    renderPremiumStatsBlock = (isMobile) => {
        if (this.props.user?.role === VIP_USER_ROLE) {
            return null;
        }
        if (!this.props.table[0]?.leagueVip) {
            return null;
        }
        if (isMobile) {
            return (
                <div className="table-row header-row primary" onClick={this.goToPricing}>
                    <div className="table-field table-field-team home"></div>
                    <div className="table-field table-field-pts"></div>
                    <div className="table-field table-field-team away link">Vip stats >></div>
                </div>
            )
        }
        return (
            <div className="table-row header-row primary" onClick={this.goToPricing}>
                <div className="table-field table-field-date"></div>
                <div className="table-field table-field-team home"></div>
                <div className="table-field table-field-pts"></div>
                <div className="table-field table-field-team away"></div>
                <div className="table-field table-field-stats"></div>
                <div className="table-field table-field-odds link">Vip stats >></div>
            </div>
        )
    }
    render() {
        const {loading} = this.props;
        return (
            <div className="matches-table">

                {loading ? <h3 className="matches-table-title" style={{color: 'white'}}>
                   Country - <span>League</span>
                </h3> : <h3 className="matches-table-title">
                    {this.props.table[0].league.country.name} - <span>{this.props.table[0].league.name}</span>
                </h3>}

                <div className="table desktop">
                    <div className="table-row header-row">
                        <div className="table-field table-field-date"></div>
                        <div className="table-field table-field-team home">Home</div>
                        <div className="table-field table-field-pts">Pts</div>
                        <div className="table-field table-field-team away">Away</div>
                        <div className="table-field table-field-stats">Stats</div>
                        <div className="table-field table-field-odds">Odds</div>
                    </div>
                    {!loading ? this.props.table.map((match, index) => {
                        return <MatchRow  match={match} key={match.matchId} toMatch={toMatch} />
                    }
                    ) : [1,2,3].map((i, index) => {
                        return (
                            <div className={`table-row ${false ? "table-row-higlighted" : ""}`} key={index}>
                                <div className="table-field table-field-date">
                                    <div style={this.getLoadingViewStyles(58)}></div>
                                </div>

                                <div className="table-field table-field-team home">
                                    <div className="team-cards">
                                    </div>
                                    <div style={this.getLoadingViewStyles(i % 2 !== 0 ? 75 : 50)}></div>
                                </div>
                                <div className="table-field table-field-pts">
                                    <div style={this.getLoadingViewStyles(50)}></div>
                                </div>
                                <div className="table-field table-field-team away">
                                    <div style={this.getLoadingViewStyles(i % 2 !== 0 ? 75 : 50)}></div>
                                    <div className="team-cards">
                                        <div className="team-cards">
                                        </div>
                                    </div>
                                </div>

                                <div className="table-field table-field-stats">
                                        <div className="data-fields">
                                            <div style={this.getLoadingViewStyles('100%')}></div>
                                            <div style={this.getLoadingViewStyles('100%')}></div>
                                            <div style={this.getLoadingViewStyles('100%')}></div>
                                        </div>
                                </div>
                                <div className="table-field table-field-odds">
                                    <div className="data-fields">
                                        <div style={this.getLoadingViewStyles('100%')}></div>
                                        <div style={this.getLoadingViewStyles('100%')}></div>
                                        <div style={this.getLoadingViewStyles('100%')}></div>
                                    </div>
                                </div>
                            </div>)
                    })}
                    {this.renderPremiumStatsBlock()}
                </div>

                <div className="table mobile">
                    <div className="table-row header-row">
                        <div className="table-field table-field-team home">Home</div>
                        <div className="table-field table-field-pts">Pts</div>
                        <div className="table-field table-field-team away">Away</div>
                    </div>
                    {!loading ? this.props.table.map((match, index) => {
                        return <MatchRow key={match.matchId} isMobile match={match} toMatch={toMatch} />
                    }
                    ) : [1,2,3].map((i, index) => {
                        return (
                            <div className={`table-row double-row ${false ? "table-row-higlighted" : ""}`} key={index}>
                                <div className="table-row-top">

                                    <div style={{textAlign: 'end'}} className="table-field table-field-team home">

                                        <span style={{textDecoration: 'underline'}} className="team-name">
                                            <div style={this.getLoadingViewStyles(75)}></div>
                                        </span>
                                    </div>
                                    <div className="table-field table-field-pts">
                                        <div style={this.getLoadingViewStyles(50)}></div>
                                    </div>
                                    <div style={{textAlign: 'start'}} className="table-field table-field-team away">
                                        <span style={{textDecoration: 'underline'}} className="team-name">
                                            <div style={this.getLoadingViewStyles(75)}></div>
                                        </span>

                                    </div>
                                </div>
                                <div className={"table-row-bottom"}>
                                    <div className="table-field table-field-stats">
                                            <div className="data">
                                                <div className="data-name">Stats:</div>
                                                <div className="data-fields">
                                                    <div style={this.getLoadingViewStyles(24)}></div>
                                                    <div style={this.getLoadingViewStyles(24)}></div>
                                                    <div style={this.getLoadingViewStyles(24)}></div>
                                                </div>
                                            </div>

                                    </div>
                                    <div className="table-field table-field-odds">
                                        <div className="data">
                                            <div className="data-name">Odds:</div>
                                            <div className="data-fields">
                                                <div style={this.getLoadingViewStyles(24)}></div>
                                                <div style={this.getLoadingViewStyles(24)}></div>
                                                <div style={this.getLoadingViewStyles(24)}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                    })}
                {this.renderPremiumStatsBlock(true)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({auth}) => ({
    user: auth.user,
    authenticated: auth.authenticated,
});

export default connect(mapStateToProps, null)(withRouter(MatchesTable));