import {all, fork} from 'redux-saga/effects';

import * as authWatchers from './auth';
import * as appWatchers from './app';

export default function* root() {
  yield all([
    fork(authWatchers.watchSignIn),
    fork(authWatchers.watchSignUp),
    fork(authWatchers.watchLogOut),
    fork(authWatchers.watchUpdateToken),
    fork(appWatchers.watchGetIpinfo),
  ]);
}
