import axios from 'axios';
import TokenStorage from '../storage/token';

import { env } from '../../env';

export const baseURL = env.REACT_APP_BASE_URL + '/api';

const http = axios.create({
  withCredentials: false,
  baseURL,
  headers: {'Content-Type': 'application/json'},
});

http.interceptors.request.use(
  async request => {
    const token = TokenStorage.get();
    const newRequest = {...request};
    if (token) {
      newRequest.headers.Authorization = `Bearer ${token}`;
    }
    return newRequest;
  },
  error => Promise.reject(error),
);

export default http;
