import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, authenticated, url, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (props.location.pathname === '/sign-out') {
          return <Component {...props} />;

        }
        if (authenticated) {
          return <Component {...props} />;
        }
        return <Redirect to={{ pathname: url, state: { from: props.location } }} />;
      }}
    />
  );
}

const mapStateToProps = ({ app }) => ({
  url: app.url,
});

export default connect(mapStateToProps, null)(ProtectedRoute);
