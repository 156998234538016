import Axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";

const SAVE_URL = 'app/SAVE_URL';

const GET_IPINFO_REQUEST = 'app/GET_IPINFO_REQUEST';
const GET_IPINFO_SUCCESS = 'app/GET_IPINFO_SUCCESS';

export const initialState = {
  url: '',
  ipinfo: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE_URL:
      return {...state, url: action.payload};

    case GET_IPINFO_SUCCESS:
        return {...state, ipinfo: action.ipinfo};
    default:
      return state;
  }
}

// <<<ACTIONS>>>
export const requestSaveUrl = payload => {
  return ({
    type: SAVE_URL,
    payload,
  });
}

export const getIpinfoRequest = () => ({
  type: GET_IPINFO_REQUEST,
})

function* getIpinfo() {
  try {
    const {data} = yield call(Axios.get, 'https://ipinfo.io/?token=170cb214aa0e21');
    yield put({
      type: GET_IPINFO_SUCCESS,
      ipinfo: data,
    });
  } catch (err) {
    console.log(err);
  }
}

export function* watchGetIpinfo() {
  yield takeEvery(GET_IPINFO_REQUEST, getIpinfo);
}
