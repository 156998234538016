import React, { useContext } from 'react';
import {requestSignIn} from '../redux/auth';
import logo from '../assets/img/logo_black.png';

import '../assets/css/pages/signup.scss';
import { connect } from 'react-redux';
import { RouterContext } from '../RouterContext';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { baseURL } from '../services/http';

class ResetPassword extends React.Component {
  static contextType = RouterContext;
  state = {
    password: '',
    repeatPassword: '',
    errors: {
      password: '',
      repeatPassword: '',
    },
  }

  onInputChange = (value, type) => {
    this.setState({[type]: value});
  };

  isButtonDisabled = () => {
    const {repeatPassword, password} = this.state;
    if (!repeatPassword || !password) {
      return true;
    }
    return false;
  };

  setInputError = (error, type) => this.setState(prevState => ({errors: { ...prevState.errors, [type]: error}}));

  validate = () => {
    let isValid = true;
    const {password, repeatPassword} = this.state;
    if (repeatPassword.length < 6) {
      isValid = false;
      this.setInputError('Minimum 6 characters required', 'repeatPassword');
    }
    if (password.length < 6) {
      isValid = false;
      this.setInputError('Minimum 6 characters required', 'password');
    }
    if (password !== repeatPassword) {
      isValid = false;
      this.setInputError('Passwords must be identical', 'repeatPassword');
    }
    return isValid;
  };

  onSubmit = async () => {
    const token = this.props.location.search.split('=')[1];
    const {repeatPassword, password} = this.state;
    this.setState({errors: {
      repeatPassword: '',
      password: '',
    }});
    const validated = this.validate();
    if (!validated) return;
    this.setState({errors: {
      repeatPassword: '',
      password: '',
    }});
    try {
      const {data, status} = await axios.post(`${baseURL}/Account/resetPassword`, {
      password: password,
      confirmPassword: repeatPassword,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (status === 200) {
        this.props.history.push('/');
      }
    } catch (err) {
      this.setState({errors: {
        repeatPassword: 'Something went wrong',
        password: 'Something went wrong',
      }});
    }
  }

  render() {
    return (
      <div className="signup">
        <div className="container">
        <NavLink to="/"><img style={{height: 39, width: 155, marginBottom: 34}} src={logo} alt="logo" /></NavLink>
        <div className="form">
          <h2 className="title"> Reset password </h2>
          <div className="input-container">
            <input style={{border: this.state.errors.password ? '2px solid red' : '1px solid #040707'}} onChange={e => this.onInputChange(e.target.value, 'password')} type="password" name="password" id="password" class="form-input" placeholder="New Password" />
            <span className="error">{this.state.errors.password}</span>
          </div>
          <div className="input-container">
            <input style={{border: this.state.errors.repeatPassword ? '2px solid red' : '1px solid #040707'}} onChange={e => this.onInputChange(e.target.value, 'repeatPassword')} type="password" name="repeatPassword" id="repeatPassword" class="form-input" placeholder="Confirm New Password" />
            <span className="error">{this.state.errors.repeatPassword}</span>
          </div>
          <div className="submit-container">
            <button className={this.isButtonDisabled() ? "disabled" : 'null'} onClick={this.onSubmit}>Confirm</button>
          </div>
        </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({auth, app}) => ({
  authenticated: auth.authenticated,
  authLoading: auth.authLoading,
  authError: auth.authError,
  url: app.url,
});

const mapDispatchToProps = {
  requestSignIn,
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);