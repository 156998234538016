import React from 'react';
import ReactDOM from 'react-dom';
import GA4React from "ga-4-react";
import { Provider } from 'react-redux';
import UserStorage from './services/storage/user';

import App from './App';
import configureStore from './redux/configureStore';
export const ga4react = new GA4React("G-KXC72FLCKH");

const user = UserStorage.get();

const store = configureStore({
  auth: {
    authenticated: !!user,
    user,
  }
});

(async _ => {
  await ga4react.initialize();
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
})();

