import React, { useState, useEffect } from 'react';
import * as signalR from '@aspnet/signalr';
import { env } from '../../env';

const SignalR = ({onNewMessage}) => {
    const [ connection, setConnection ] = useState(null);

    useEffect(() => {
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(env.REACT_APP_BASE_URL + '/hubs/live')
            /** Log level for very low severity diagnostic messages. */
            // Trace = 0,
            // /** Log level for low severity diagnostic messages. */
            // Debug = 1,
            // /** Log level for informational diagnostic messages. */
            // Information = 2,
            // /** Log level for diagnostic messages that indicate a non-fatal problem. */
            // Warning = 3,
            // /** Log level for diagnostic messages that indicate a failure in the current operation. */
            // Error = 4,
            // /** Log level for diagnostic messages that indicate a failure that will terminate the entire application. */
            // Critical = 5,
            // /** The highest possible log level. Used when configuring logging to indicate that no log messages should be emitted. */
            // None = 6
            .configureLogging(6)
            .build()

        
        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    connection.on('ReceiveMessage', message => {
                      onNewMessage(message);
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
        return () => {
            if (connection) {
                connection.stop();
            }
        }
    }, [connection]);

    return (
        <div>

        </div>
    );
};

export default SignalR;